import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Footer from './components/Footer';
import Introduction from './components/Introduction';
import Education from './components/Education';
import Experiences from './components/Experiences';
import './index.css';

function App() {
  return (
    <div className='main-container'>
      <Navbar className='main-container'/>  
      <Hero  className='main-container'/>
      <Introduction  className='main-container'/>
      <Education  className='main-container'/>
      <Experiences className='main-container'/>
      <Projects className='main-container'/>
      <Footer className='main-container'/>
    </div>
  );
}

export default App;
