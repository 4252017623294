import React from "react";
import { motion } from "framer-motion";
import { ReactTyped } from "react-typed";
import {
  AiOutlineDownload,
  AiOutlineCalendar,
  AiOutlineArrowDown,
} from "react-icons/ai";
import GradientBackground from "./GradientBackground";
import pfp from "../pics/polaroid2.jpg";

const Hero = () => {
  const scrollToFooter = () => {
    const footerElement = document.getElementById("footer2");
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAboutMe = () => {
    const aboutMeElement = document.getElementById("about-me");
    if (aboutMeElement) {
      aboutMeElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Framer Motion variants for animations
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  };

  const fadeInImage = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1, ease: "easeOut", delay: 1.2 },
    },
  };

  const fadeInGradient = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.2, ease: "easeOut" } },
  };

  const staggeredFadeIn = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <div
      id="home"
      className="relative w-full h-auto pt-8 pb-20 text-white bg-black"
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInGradient}
        className="absolute inset-0"
      >
        <GradientBackground />
      </motion.div>

      <motion.div
        className="z-10 flex flex-col justify-center w-5/6 h-auto mx-auto md:w-3/4 hero-content"
        initial="hidden"
        animate="visible"
        variants={staggeredFadeIn}
      >
        {/* Name and Intro */}
        <motion.div
          className="m-0 mb-2 text-3xl text-right md:text-center dongle"
          variants={fadeIn}
        >
          <p className="py-1 thin">Hi, my name is </p>
          <span className="font-bold text-transparent gradient-text animate-gradient whitespace-nowrap">
            Anirudh Pottammal<span className="text-white">.</span>
          </span>
        </motion.div>

        {/* Profile Image */}
        <motion.div variants={fadeInImage}>
          <img
            src={pfp}
            alt="Ani Potts"
            className="object-cover w-auto mx-auto mt-4 mb-8 transition duration-300 ease-in-out rounded-lg shadow-xl hover:shadow-2xl h-72 md:h-96 hover:scale-105 z-100 md:my-8"
          />
        </motion.div>

        {/* Typed Text Section */}
        <motion.div
          className="flex flex-col md:text-left md:mx-auto md:flex-row"
          variants={fadeIn}
        >
          <p className="pb-2 pr-3 text-4xl font-thin md:font-bold lg:text-5xl whitespace-nowrap dongle">
            I really love
          </p>
          <ReactTyped
            className="pb-2 text-4xl font-bold text-left text-transparent md:text-center lg:text-5xl dongle gradient-text animate-gradient whitespace-nowrap"
            strings={[
              "math!",
              "programming.",
              "quantitative finance.",
              "solving puzzles.",
              "probability theory.",
              "machine learning.",
              "data analysis.",
              "stochastic calculus.",
            ]}
            typeSpeed={100}
            backSpeed={60}
            loop
          />
        </motion.div>

        {/* Buttons */}
        <motion.div
          className="flex flex-row gap-10 mt-4 font-bold md:justify-center lg:mt-6 dongle"
          variants={fadeIn}
        >
          <button
            onClick={scrollToAboutMe}
            className="flex flex-row cursor-n-resize px-2 py-[6px] text-white bg-[#5a48ff] hover:text-[#5a48ff] rounded-lg hover:bg-[#ddd5ff] shadow-xl lg:shadow-none hover:scale-105 transition duration-200 whitespace-nowrap md:hidden outline outline-offset-4 outline-white hover:animate-pulse"
          >
            <AiOutlineArrowDown className="mr-1 text-2xl animate-bounce" />{" "}
            <span className="mr-1 font-semibold">More About Me</span>
          </button>

          <a
            href="/ani.pottammal_nyu_2026_resume.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="cursor-n-resize px-4 py-2 text-white bg-[#5a48ff] hover:text-[#5a48ff] rounded-lg hover:bg-[#ddd5ff] hover:scale-105 items-center transition duration-200 justify-center whitespace-nowrap hidden md:flex outline outline-2 shadow-xl hover:shadow-2xl outline-white outline-offset-4 hover:animate-pulse">
              <AiOutlineDownload className="mr-2" /> <span>Resume</span>
            </button>
          </a>

          <button
            onClick={scrollToFooter}
            className="cursor-n-resize px-4 py-2 text-white bg-[#5a48ff] hover:text-[#5a48ff] rounded-lg hover:bg-[#ddd5ff] hover:scale-105 items-center transition duration-200 justify-center whitespace-nowrap hidden md:flex outline outline-2 shadow-xl hover:shadow-2xl outline-white outline-offset-4 hover:animate-pulse"
          >
            <AiOutlineCalendar className="mr-2" />{" "}
            <span>Tutoring Services</span>
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Hero;
