import React, { useState, useEffect } from "react";
import image1 from "../pics/ani_pfp.jpg";
import image2 from "../pics/ani_city.jpg";
import image3 from "../pics/fam1.jpg";
import image4 from "../pics/fam2.jpg";
import "../index.css";
import {
  AiOutlineStock,
  AiOutlineCalculator,
  AiOutlineCode,
} from "react-icons/ai";
import {
  FaLaptopCode,
  FaBrain,
  FaDatabase,
  FaSquareRootAlt,
} from "react-icons/fa";
import {
  MdBarChart,
  MdMusicNote,
  MdOutlineCastForEducation,
} from "react-icons/md";

const Introduction = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [image1, image2, image3, image4];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [images.length]);

  return (
    <div
      id="about-me"
      className="px-4 py-12 lg:py-[50px] h-auto bg-transparent"
    >
      <div className="relative z-10 flex flex-col items-center w-5/6 py-12 m-auto lg:flex-row">
        <div className="p-8 slider lg:w-1/2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="Ani Potts"
              className={`object-cover h-96 mx-auto outline outline-2 outline-white outline-offset-8 rounded-lg hover:-translate-y-2 lg:hover:scale-105 transition duration-300 shadow-xl hover:shadow2xl lg:h-[30rem] ${
                index === currentSlide ? "block" : "hidden"
              }`}
            />
          ))}
        </div>
        <div className="text-center lg:w-1/2 lg:text-left">
          <h2 className="pt-12 pb-4 text-4xl font-semibold text-white dongle lg:text-5xl">
            About Me
          </h2>
          <p className="pb-8 text-xl prose text-white lg:pb-4 font-extralight dongle line-spacing">
            <div className="flex justify-center gap-8 p-4 text-3xl text-white">
              <AiOutlineCalculator className="transition-transform transform hover:scale-110" />
              <AiOutlineCode className="transition-transform transform hover:scale-110" />
            </div>
            I'm a sophomore studying{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              math
            </span>{" "}
            and{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              computer science
            </span>
            , and I will almost surely be learning math for the rest of my life.
            I'm currently interested in researching{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              probabalistic systems.
            </span>
          </p>
          <p className="pb-8 text-xl font-thin prose text-white lg:pb-4 dongle line-spacing">
            <div className="flex justify-center gap-8 p-4 text-3xl text-white">
              <AiOutlineStock className="transition-transform transform hover:scale-110" />
              <FaBrain className="transition-transform transform hover:scale-110" />
              <FaDatabase className="transition-transform transform hover:scale-110" />
            </div>
            I'm passionate about{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              mathematical finance
            </span>
            ,{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              machine learning
            </span>
            , and{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              data analysis
            </span>
            . Currently, I'm exploring these fields in two separate collegiate
            quantitative trading clubs as an analyst.
          </p>
          <p className="pb-8 text-xl prose text-white lg:pb-4 font-extralight dongle line-spacing">
            <div className="flex justify-center gap-8 p-4 text-3xl text-white">
              <FaLaptopCode className="transition-transform transform hover:scale-110" />
              <MdBarChart className="transition-transform transform hover:scale-110" />
              <MdMusicNote className="transition-transform transform hover:scale-110" />
            </div>
            I also have over a year of experience working with technology at{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              NYU IT
            </span>{" "}
            and{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              NYU College of Dentistry
            </span>
            {/* , and I'm currently working on music industry data analytics for{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              Our Bad Habit
            </span> */}
            .
          </p>
          <p className="pb-8 text-xl prose text-white lg:pb-4 font-extralight dongle line-spacing">
            <div className="flex justify-center gap-8 p-4 text-3xl text-white">
              <MdOutlineCastForEducation className="transition-transform transform hover:scale-110" />
              <FaSquareRootAlt className="transition-transform transform hover:scale-110" />
            </div>
            In my free time I am a{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              math tutor
            </span>{" "}
            for elementary, middle, and high school students, and I help them
            get a jump start on advanced topics, such as{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              algebra
            </span>
            ,{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              geometry
            </span>
            , and{" "}
            <span className="font-bold text-transparent gradient-text animate-gradient">
              calculus
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
