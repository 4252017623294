import React from "react";
import quantercise from "../pics/quantercise.jpg";
import password from "../pics/password.jpg";
import portfolio from "../pics/portfolio_optimization.jpg";
import option from "../pics/option_pricing.jpg";
import strategy from "../pics/option_strategy.jpg";
import momentum_trading from "../pics/momentum_trading.jpg";
import { AiOutlineInfoCircle, AiOutlineCode } from "react-icons/ai";

const Projects = () => {
  const projects = [
    {
      title: "Quantercise",
      brief:
        "Platform for students to exercise their quantitative skills for trading/analyst internships",
      description: "",
      imageUrl: quantercise,
      projectUrl: "https://quantercise.com",
      codeUrl: "#",
    },
    {
      title: "Parameterized Momentum Trading",
      brief:
        "Developed a Python script to automate momentum trading on DIJA constituents and portfolio performance from 2004 - 2024. The program uses a hyperparameterized approach to adjust the strategy for different market conditions.",
      description: "This is a long description of Portfolio Optimization.",
      imageUrl: momentum_trading,
      projectUrl:
        "https://github.com/anirudhp15/momentum-trading/blob/main/Parameter_Tuning_in_Momentum_Strategies.pdf",
      codeUrl:
        "https://github.com/anirudhp15/momentum-trading/blob/main/momemtum.ipynb",
    },
    {
      title: "Option/Equity Trading Strategy",
      brief:
        "Preprocessed and analyzed CQAI datasets to develop a quant-driven trading strategy centered on the option-to-equity volume ratio for signals. Constructed a factor model using beta coefficients to balance exposure and optimize market returns.",
      description: "CloudQuant notebook that implements ",
      imageUrl: strategy,
      projectUrl: "#",
      codeUrl: "#",
    },
    {
      title: "Portfolio Optimization",
      brief:
        "This Python tool uses advanced finance algorithms and Python libraries to enhance investment portfolios. It assesses and boosts potential annual returns, delivering a thorough performance analysis of the optimized investment strategy.",
      description:
        "Jupyter notebook that uses the Efficient Frontier to optimize a portfolio of stocks during the COVID pandemic, and uses Sharpe Ratio to determine the best portfolio.",
      imageUrl: portfolio,
      projectUrl:
        "https://github.com/anirudhp15/Portfolio-Optimization/tree/main",
      codeUrl:
        "https://github.com/anirudhp15/Portfolio-Optimization/blob/main/portfolio_optimization.ipynb",
    },
    {
      title: "Option Pricing & Sensitivity Analysis",
      brief:
        "This Python program is designed to calculate the price of a European option using two different pricing models: the Black-Scholes option pricing model and the binomial option pricing model.",
      description:
        "Python program that prices options using the Black-Scholes model and binomial pricing model, and performs sensitivity analysis with respect to changes in the underlying asset, volatility, and time to maturity.",
      imageUrl: option,
      projectUrl:
        "https://github.com/anirudhp15/Options-Pricing-and-Sensitivity-Analysis-Tool/tree/main",
      codeUrl:
        "https://github.com/anirudhp15/Options-Pricing-and-Sensitivity-Analysis-Tool/blob/main/options_pricing.py",
    },
    {
      title: "Password Generator & Validator",
      brief:
        "This Python program offers streamlined password generation and robust validation, ensuring users can easily create and verify the strength of their passwords.",
      description:
        "Python program that generates a random password of preferred complexity, and validates password strength to user",
      imageUrl: password,
      projectUrl: "https://github.com/anirudhp15/Password-Generator-Validator",
      codeUrl:
        "https://github.com/anirudhp15/Password-Generator-Validator/blob/main/project/project.py",
    },

    // Add more projects here
  ];

  return (
    <div
      id="projects"
      className="relative z-10 w-full py-12 mx-auto bg-transparent dongle"
    >
      <h2 className="w-5/6 py-8 m-auto text-5xl font-bold text-center text-white dongle lg:text-left">
        Projects
      </h2>
      <div className="grid w-5/6 grid-cols-1 gap-6 m-auto lg:grid-cols-2 xl:grid-cols-3">
        {projects.map((project, index) => (
          <div
            key={index}
            className="p-3 m-4 overflow-hidden transition-transform duration-300 ease-in-out transform bg-white rounded-lg shadow-lg lg:m-0 hover:shadow-2xl hover:-translate-y-2 lg:hover:translate-x-2"
          >
            <img
              src={project.imageUrl}
              alt={project.title}
              className="object-cover w-full h-48 rounded-sm outline outline-black outline-4"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold">{project.title}</h3>
              <p className="mt-2 text-gray-600">{project.brief}</p>
              <div className="flex flex-row justify-center gap-4 pt-6">
                <a
                  href={project.projectUrl}
                  role="button"
                  className="px-3 py-[6px] font-bold outline outline-2 outline-[#5a48ff] mr-4 text-white lg:text-[#5a48ff] lg:bg-transparent lg:hover:text-white lg:hover:bg-[#5a48ff] lg:shadow-none bg-[#5a48ff] hover:text-[#5a48ff] rounded-lg hover:bg-[#ddd5ff] shadow-md hover:shadow-xl hover:scale-105 transition duration-200 whitespace-nowrap"
                >
                  <AiOutlineInfoCircle className="inline-block mb-1 mr-2" />
                  Details
                </a>
                <a
                  href={project.codeUrl}
                  role="button"
                  className="px-3 py-[6px] font-bold outline outline-2 outline-[#5a48ff] text-white lg:text-[#5a48ff] lg:bg-transparent lg:hover:text-white lg:hover:bg-[#5a48ff] lg:shadow-none bg-[#5a48ff] hover:text-[#5a48ff] rounded-lg hover:bg-[#ddd5ff] shadow-md hover:shadow-xl hover:scale-105 transition duration-200 whitespace-nowrap"
                >
                  <AiOutlineCode className="inline-block mb-1 mr-2" />
                  View Code
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
